<template>
  <!-- 确认身份证信息 -->
  <div class="main-container">
    <p class="rem36 f500 c-303133 check-info">请检查您的身份信息</p>
    <div class="check-userinfo">
      <van-field v-model="userInfo.name" label="姓名">
        <!-- <template #extra>
          <van-uploader>
            <img :src="cameraPng" class="img26" alt="" />
          </van-uploader>
        </template> -->
      </van-field>
      <van-field v-model="userInfo.idNo" label="证件号码" />
      <div v-if="showMore">
        <div class="check-color">
          <van-field v-model="userInfo.gender" readonly label="性别" />
          <van-field v-model="userInfo.nation" readonly label="民族" />
        </div>
        <van-field v-model="userInfo.birthDay" label="出生日期" />
        <van-field v-model="userInfo.address" type="textarea" label="户籍地址" />
      </div>
      <div class="c-4D7BFE mt8 flex align-center just-center" @click="showMoreFn">
        更多信息
        <img :src="downPng" alt="" class="img16" v-if="!showMore" />
        <img :src="upPng" alt="" class="img16" v-else />
      </div>
    </div>
    <div class="check-userinfo mt20">
      <van-field v-model="userInfo.issuedBy" label="签发机关"> </van-field>
      <div class="check-color">
        <van-field v-model="userInfo.validityPeriod" readonly label="有效期限" />
      </div>
    </div>
    <!-- <div class="check-userinfo mt20">
      <div class="check-color">
        <van-field v-model="userInfo.mobile" label="手机号码" readonly> </van-field>
      </div>
      <div>
        <van-field
          v-model="userInfo.authCode"
          placeholder="请输入验证码"
          label="短信验证码"
          @blur="codeBlur"
        >
          <template #button>
            <span class="rem28 c-4D7BFE" @click="getCode" v-if="countDown == 0">{{
              codetext
            }}</span>
            <span class="rem28 c-4D7BFE" v-else>{{ countDown }}s</span>
          </template>
        </van-field>
      </div>
    </div> -->
    <div class="mt40">
      <van-button round block type="info" @click="nextStep">下一步</van-button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Field, Cell, Button, Icon, Uploader } from 'vant'
import {
  sendCodeByThreeBasicApi,
  CBAuthByTwoBasicApi,
  userCompanyListApi,
} from '@/apis/credit/index'

import loadingIndex from '@/utils/overlay'
Vue.use(loadingIndex)

Vue.use(Field)
Vue.use(Cell)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Uploader)
import { Encrypt, Decrypt } from '@/utils/crypto'

export default {
  data() {
    return {
      userInfo: {
        name: '',
        idNo: '',
        gender: '',
        nation: '',
        birthDay: '',
        address: '',
        issuedBy: '',
        validityPeriod: '',
        mobile: '',
        authCode: '',
      },
      cameraPng: require('@/assets/images/borrow/camera.png'),
      downPng: require('@/assets/images/borrow/down.png'),
      upPng: require('@/assets/images/borrow/up.png'),
      showMore: false,
      codetext: '获取验证码',
      countDown: 0,
      status: 'ready',
      isCompanyEmpty: false, // 用户是否拥有企业
    }
  },
  created() {
    this.userInfo = JSON.parse(this.$route.query.userInfo)
    this.userInfo.idNo = Decrypt(this.userInfo.idNo)
    this.userInfo.mobile = Decrypt(this.userInfo.mobile)
    this.userCompanyList()
  },
  computed: {},
  methods: {
    showMoreFn() {
      this.showMore = !this.showMore
    },
    // 查询企业白名单
    userCompanyList() {
      userCompanyListApi().then((res) => {
        if (res.code === 200) {
          let list = res.data || []
          if (list.length === 0) {
            this.isCompanyEmpty = true
          } else {
            this.isCompanyEmpty = false
          }
        }
      })
    },
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },
    hideloading() {
      this.$cancelLoading()
    },
    nextStep() {
      this.showloading()
      // 三要素认证
      let params = JSON.parse(JSON.stringify(this.userInfo))
      params.frontImageTypeCode = 1
      params.reverseImageTypeCode = 2
      params.reverseImgId = params.reverImgId
      params.idNo = Encrypt(params.idNo)
      CBAuthByTwoBasicApi(params)
        .then((res) => {
          if (res.code === 200) {
            if (this.isCompanyEmpty) {
              this.$router.push({
                path: '/add-company-info',
              })
            } else {
              this.$router.push({
                path: '/company-info',
              })
            }
          }
          this.hideloading()
        })
        .catch(() => {
          this.hideloading()
        })
    },
    // 输入验证码
    codeBlur() {
      if (this.userInfo.authCode.length === 6) {
        this.countDown = 0
        clearInterval(this.timer)
        this.status = 'ready'
        this.codetext = '重新获取'
      }
    },
    // 三要素获取验证码
    getCode() {
      // if (!this.$validate.isPhone(this.userInfo.mobile)) {
      //   this.$common.toast('请输入正确的手机号')
      //   return
      // }
      if (this.status == 'ready') {
        this.codetext = '获取中'
        this.status = 'padding'
        let params = JSON.parse(JSON.stringify(this.userInfo))
        // params.idNo = Encrypt(params.idNo)
        params.idNo = Encrypt(params.idNo)
        params.mobile = Encrypt(params.mobile)
        sendCodeByThreeBasicApi(params)
          .then((res) => {
            if (res.code === 200) {
              // this.$common.toast(res.data)
              this.countDown = 60
              this.timer = setInterval(() => {
                this.countDown--
                if (this.countDown == 0) {
                  clearInterval(this.timer)
                  this.codetext = '重新获取'
                  this.status = 'ready'
                }
              }, 1000)
            } else {
              this.codetext = '重新获取'
              this.status = 'ready'
              this.$common.toast(res.data)
            }
          })
          .catch((_) => {
            this.status = 'ready'
          })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.van-cell {
  padding: 0;
  padding-bottom: 0.12rem;
  border-bottom: 1px solid #e7e7e7;
  margin-top: 0.32rem;
}
.check-info {
  margin-bottom: 0.4rem;
}
.check-userinfo {
  padding: 0.02rem 0.28rem 0.28rem 0.4rem;
  box-shadow: 0px 0px 0.3rem 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.08rem;
  .check-color {
    /deep/ .van-field__control {
      color: #b9bcc2 !important;
    }
  }
}
/deep/ .van-icon__image {
  width: 0.52rem;
  height: 0.52rem;
}
</style>
